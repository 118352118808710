import { useQuery } from "@tanstack/react-query"
import { UserData } from "../pages/api/user"

export const useUserData = (initUserData: UserData | null = null) => {
  const {
    data: user,
    isLoading,
    error
  } = useQuery<UserData>({
    queryKey: ["ligonierUser"],
    queryFn: async () => {
      try {
        const response = await fetch("/api/user", {
          credentials: "include"
        })
        if (response.ok && response.status === 200) {
          const json = await response.json()
          return json
        } else {
          const errorMessage = await response.text()
          throw new Error(errorMessage)
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    cacheTime: 60 * 1000 * 5, // 5 minutes
    staleTime: 60 * 1000, // 1 minutes
    retry: false, // without this it's causing refresh token issues
    initialData: initUserData
  })

  return {
    user,
    isLoading,
    error
  }
}
