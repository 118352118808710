import { useContext, useEffect, useState } from "react"
import { connect, FormikContextType } from "formik"
import * as Sentry from "@sentry/nextjs"
import { useUserData } from "../hooks/useUserData"
import { UserData } from "../pages/api/user"
import { ServiceContext } from "../pages/[id]/[slug]"
import { useRouter } from "next/router"
import Link from "next/link"

interface LoginProps {
  userData: UserData
  isEnglish: boolean
  isCampaignForNewUsersOnly: boolean
  origin: string
}

export const LoginComponent = ({
  formik,
  userData,
  isEnglish,
  isCampaignForNewUsersOnly,
  origin
}: LoginProps & { formik: FormikContextType<{}> }) => {
  const baseUrl = process.env.NEXT_PUBLIC_HOST

  const { donationAmountService, recurringService } = useContext(ServiceContext)
  const { user } = useUserData(userData)
  const [donationAmountContext, setDonationAmountContext] = useState(null)
  const [_, setRecurringContext] = useState(null)
  const router = useRouter()

  const currentUrl = `${origin}${router.asPath}`

  const updateFields = (userObj) => {
    // Now we update the formik Fields
    Object.keys(userObj).forEach(async (userField) => {
      try {
        // prefill user data except for payment method
        if (userField !== "payment_methods") {
          await formik.setFieldValue(userField, userObj[userField])
          await formik.setFieldTouched(userField, true)
        }
      } catch (error) {
        console.error("FormikFormError:", error)
        Sentry.captureException(error)
      }
    })
  }

  useEffect(() => {
    const subscription = donationAmountService.subscribe((state) => {
      setDonationAmountContext(state.context)
    })

    return subscription.unsubscribe
  }, [donationAmountService])

  useEffect(() => {
    const subscription = recurringService.subscribe((state) => {
      setRecurringContext(state.context)
    })

    return subscription.unsubscribe
  }, [recurringService])

  useEffect(() => {
    if ((user || userData) && !isCampaignForNewUsersOnly) {
      updateFields(userData || user)
    }
  }, [user, userData])

  const callbackUrl = new URL(
    `${baseUrl}/${router.query.id}/${router.query.slug}`
  )

  if (donationAmountContext?.amount || donationAmountContext?.amountSelected) {
    callbackUrl.searchParams.append(
      "amount",
      donationAmountContext.amount || donationAmountContext.amountSelected
    )
  }

  if (!user) {
    return (
      <div className="login">
        <p id="login-text" className="ss5 mb-4" data-testid="login-button">
          {isEnglish ? "Already have an account?" : "¿Ya tienes una cuenta?"}{" "}
          <Link
            href={`/api/auth/pkce_authorize?location=${encodeURIComponent(
              currentUrl
            )}`}
            passHref
          >
            <a id="login-btn">{isEnglish ? "Sign in" : "Inicie sesión"}</a>
          </Link>{" "}
          {isEnglish
            ? "to prefill your account details and choose from saved payment methods."
            : "para completar los detalles de su cuenta y elegir entre los métodos de pago guardados."}{" "}
        </p>
      </div>
    )
  } else {
    return null
  }
}

export const Login = connect<LoginProps>(LoginComponent)
